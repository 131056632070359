import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Col, Container, Row } from "react-grid-system"
import { Display } from "../components/typography"

const ReferencePage = ({ data: { reference } }) => {
  // console.log(reference)

  return (
    <Layout>
      <SEO title={reference.name} />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <Display>Realizzazioni - {reference.name}</Display>
            </Col>
          </Row>
        </Container>
        <Container>
          <Box>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter={32}>
                {reference.gallery.map(image => (
                  <img src={image.url} key={image.originalId} />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </Box>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Box = styled.div`
  min-height: 20rem;
`

const Wrapper = styled.div`
  h1 {
    margin-top: 3rem;
  }
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ReferencePage

export const query = graphql`
  query GalleryQuery($originalId: String!) {
    reference: datoCmsReference(originalId: { eq: $originalId }) {
      name
      originalId
      gallery {
        url
        originalId
      }
    }
  }
`
